import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import WorkTogether from "../components/WorkTogether/WorkTogether";
import PageHeader from "../components/PageHeader/PageHeader";
import Values from "../components/Values/Values";
import SEO from "../components/SEO/SEO";
import Passion from "../components/Passion/Passion";
import config from "../../data/SiteConfig";

class AboutPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title={`About | ${config.siteTitle}`} />
        <SEO />
        <Header />
        <PageHeader
          title="About Us"
          content="Toptive is a software company based in Argentina with an excellent track record that has been developing high quality products for the past seven years."
        />
        <Values id="values"/>
        <Passion />
        <WorkTogether />
        <Footer />
      </Layout>
    );
  }
}

export default AboutPage;
