/* eslint-disable react/no-unescaped-entities */
import React from "react";
import pixelHeart from "../../assets/images/pixel-heart.png";
import longLegs from "../../assets/images/long-legs.png";
import CheckIcon from "../../assets/images/icons/check-icon.svg";
import CornerArrowIcon from "../../assets/images/icons/corner-arrow-icon.svg";
import SmileGlobeIcon from "../../assets/images/icons/simile-globe-icon.svg";
import SmileIcon from "../../assets/images/icons/smile-icon.svg";
import ThunderIcon from "../../assets/images/icons/thunder-icon.svg";
import UpDownIcon from "../../assets/images/icons/up-down-icon.svg";

export default function Passion() {
  return (
    <section className="container-fluid passion-section">
      <div className="row">
        <div className="col-12 col-lg-6 bg-capry robot-legs d-flex flex-row justify-content-center align-items-center" style={{ backgroundImage: `url(${longLegs})`}}>
          <img src={pixelHeart} className="heart-beat" alt="Toptive heart" />
        </div>
        <div className="col-12 col-lg-6 py-5">
          <div className="container py-2">
            <h3 className="color-capry font-weight-bold mb-4">Smart, Friendly & Passionate about our jobs</h3>
            <p>
              We have successful experiences in helping startups as well as multinational corporation by collaborating with their projects.
              Toptive is the place where development meets scale. We are here to become your partner and join forces to make your products even greater!
            </p>
            <div className="row passion-items">
              <div className="col-12 col-sm-6 my-2">
                <UpDownIcon className="color-green" />
                <p>Most importantly: we love and enjoy our job because we believe in helping others to improve better and better, as well as learning from others and establish long term partnership.</p>
              </div>
              <div className="col-12 col-sm-6 my-2">
                <CornerArrowIcon className="color-capry" />
                <p>We are not afraid of failure, learn from the mistakes so that we do not run the risk of repeating them. It's also important to admit mistakes.</p>
              </div>
              <div className="col-12 col-sm-6 my-2">
                <SmileGlobeIcon className="color-pink" />
                <p>We are flexible and we like to meet new people. We love diversity: everyone should feel welcome and respected.</p>
              </div>
              <div className="col-12 col-sm-6 my-2">
                <ThunderIcon className="color-ligth-orange" />
                <p>We love empowering each toptier developer to make decisions that apply to their own teams</p>
              </div>
              <div className="col-12 col-sm-6 my-2">
                <SmileIcon className="color-ligth-orange" />
                <p>We are collaborative and passionate</p>
              </div>
              <div className="col-12 col-sm-6 my-2">
                <CheckIcon className="color-green" />
                <p>We enjoy taking smart risks. Stakeholders satisfaction is our ultimate goal.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
