import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"

function Values({
  data: {
    allValuesYaml: { edges },
  },
  id,
}) {
  return (
    <div className="values-container bg-white" id={id}>
      <section className="container-fluid">
        <div className="container py-2">
          {edges
            .map(edge => edge.node)
            .map(node => (
              <div className="row my-5 value-item" key={node.name}>
                <div className="col-12 col-sm-6 d-flex justify-content-center align-items-center">
                  <Img fixed={node.logo.childImageSharp.fixed} />
                </div>
                <div className="col-12 col-sm-6">
                  <h3 className="mb-4">{node.title}</h3>
                  <p className="pt-1" dangerouslySetInnerHTML={{ __html: node.description }} />
                </div>
              </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query ValuesQuery {
        allValuesYaml {
          edges {
            node {
              name
              title
              description
              logo {
                childImageSharp {
                 fixed(width: 297) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Values data={data} {...props} />}
  />
)
